<template>
  <b-container>
    <div v-if="!isLoading">
      <b-card class="p-1">
        <div v-if="orderData === null" class="m-5 p-5 font-large-1 text-center">
          <span>Order with ID</span>
          <br />
          <b>{{ orderID }}</b>
          <br />
          does not exist!
        </div>
        <div v-else class="my-2">
          <app-timeline>
            <!-- Order Overview -->
            <app-timeline-item icon="ClipboardIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0">Order Overview</span>
                  </div>
                </div>

                <div
                  class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
                >
                  <!-- Order ID -->
                  <div class="d-flex justify-content-between my-1">
                    <span class="h1 font-medium-1 text-primary">Order ID</span>
                    <span class="font-medium-1 mr-1 text-right">{{
                      orderID
                    }}</span>
                  </div>

                  <!-- Status -->
                  <div class="d-flex justify-content-between my-1">
                    <span class="h1 font-medium-1 text-primary">Status</span>
                    <b-button
                      v-if="orderData.status === 'Active'"
                      variant="gradient-success"
                      size="sm"
                      pill
                    >
                      {{ orderData.status }}
                    </b-button>
                    <b-button
                      v-else-if="orderData.status === 'Assigned'"
                      variant="gradient-primary"
                      size="sm"
                      pill
                    >
                      {{ orderData.status }}
                    </b-button>
                    <b-button
                      v-else-if="orderData.status === 'In Warehouse'"
                      variant="gradient-info"
                      size="sm"
                      pill
                    >
                      {{ orderData.status }}
                    </b-button>
                    <b-button
                      v-else-if="orderData.status === 'In Transit'"
                      variant="gradient-warning"
                      size="sm"
                      pill
                    >
                      {{ orderData.status }}
                    </b-button>
                    <b-button
                      v-else-if="orderData.status === 'Out For Delivery'"
                      variant="gradient-primary"
                      size="sm"
                      pill
                    >
                      {{ orderData.status }}
                    </b-button>
                    <b-button
                      v-else-if="orderData.status === 'Delivered'"
                      variant="gradient-success"
                      size="sm"
                      pill
                    >
                      {{ orderData.status }}
                    </b-button>
                    <b-button
                      v-else-if="orderData.status === 'Second Delivery'"
                      variant="gradient-danger"
                      size="sm"
                      pill
                    >
                      {{ orderData.status }}
                    </b-button>
                    <b-button v-else variant="gradient-danger" size="sm" pill>
                      {{ orderData.status }}
                    </b-button>
                  </div>

                  <!-- Shipment -->
                  <div class="d-flex justify-content-between my-1">
                    <span class="h1 font-medium-1 text-primary">Shipment</span>
                    <b-button variant="gradient-danger" size="sm" pill>
                      {{ orderData.order_type }}
                    </b-button>
                  </div>

                  <!-- Outlet ID -->
                  <div class="d-flex justify-content-between my-1">
                    <span class="h1 font-medium-1 text-primary">Outlet ID</span>
                    <span class="font-medium-1 mr-1 text-right">
                      {{ orderData.pickup.id }}
                    </span>
                  </div>

                  <!-- Total Spent -->
                  <div class="d-flex justify-content-between my-1">
                    <span class="h1 font-medium-1 text-primary"
                      >Total Spent</span
                    >
                    <span class="font-medium-1 mr-1 text-right">
                      {{ $helpers.formatTotal(orderData.amount) }}
                    </span>
                  </div>
                </div>
              </div>
            </app-timeline-item>

            <!-- Date -->
            <app-timeline-item icon="CalendarIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0">Date</span>
                  </div>
                  <b-button
                    v-if="!isDateEditable"
                    variant="gradient-primary"
                    class="box-shadow-1"
                    size="sm"
                    pill
                    @click="toggleEdit('date')"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="15"
                      color="white"
                      class="mr-1"
                    />
                    Edit
                  </b-button>
                  <div v-else>
                    <b-button
                      variant="gradient-secondary"
                      class="box-shadow-1 mr-1"
                      size="sm"
                      pill
                      @click="toggleEdit('date')"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      :disabled="previousDate === orderData.delivery_date"
                      variant="gradient-success"
                      class="box-shadow-1"
                      size="sm"
                      pill
                      @click="updateDate()"
                    >
                      Save
                    </b-button>
                  </div>
                </div>

                <div
                  class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
                >
                  <validation-observer ref="dateValidation">
                    <!-- Order Date -->
                    <div class="d-flex justify-content-between my-1">
                      <span class="h1 font-medium-1 text-primary"
                        >Order Date</span
                      >
                      <span class="font-medium-1 mr-1 text-right">
                        {{
                          !orderData.order_placed_date
                            ? ''
                            : orderData.order_placed_date.substr(0, 10)
                        }}
                      </span>
                    </div>

                    <!-- Delivery Date -->
                    <div class="d-flex justify-content-between my-1">
                      <span class="h1 font-medium-1 text-primary pt-1">
                        Delivery Date
                      </span>
                      <span
                        v-if="!isDateEditable"
                        class="font-medium-1 mr-1 text-right pt-1"
                      >
                        {{
                          !orderData.delivery_date
                            ? ''
                            : orderData.delivery_date.substr(0, 10)
                        }}
                      </span>
                      <div v-else>
                        <validation-provider
                          #default="{ errors }"
                          name="Delivery Date"
                          rules="required"
                        >
                          <flat-pickr
                            v-model="orderData.delivery_date"
                            class="form-control font-small-2 w-auto"
                            placeholder="Choose Date"
                            :config="{
                              minDate: 'today',
                            }"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                  </validation-observer>
                </div>
              </div>
            </app-timeline-item>

            <!-- Address -->
            <app-timeline-item icon="HomeIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0">Address</span>
                  </div>

                  <b-button
                    v-if="!isAddressEditable"
                    variant="gradient-primary"
                    class="box-shadow-1"
                    size="sm"
                    pill
                    @click="toggleEdit('address')"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="15"
                      color="white"
                      class="mr-1"
                    />
                    Edit
                  </b-button>
                  <div v-else>
                    <b-button
                      variant="gradient-secondary"
                      class="box-shadow-1 mr-1"
                      size="sm"
                      pill
                      @click="toggleEdit('address')"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      variant="gradient-success"
                      class="box-shadow-1"
                      size="sm"
                      pill
                      @click="updateAddress()"
                    >
                      Save
                    </b-button>
                  </div>
                </div>

                <validation-observer
                  ref="addressValidation"
                  class="row ml-sm-5 col-xs-12 col-sm-10 col-md-10 px-0 my-1"
                >
                  <!-- Origin Address -->
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <span class="h1 font-medium-1 text-primary">
                      Origin Address
                    </span>
                    <div
                      class="w-100 border-primary rounded border-1 my-1 p-2 minimum-container-height"
                    >
                      <div v-if="!isAddressEditable">
                        <b
                          v-if="orderData.sender.last_name"
                          class="h1 font-medium-3"
                        >
                          {{
                            orderData.sender.first_name +
                            ' ' +
                            orderData.sender.last_name
                          }}
                        </b>
                        <b v-else class="h1 font-medium-3">
                          {{ orderData.sender.first_name }}
                        </b>
                        <br />
                        <div class="mt-1">
                          <span class="h1 font-medium-1">
                            {{
                              orderData.sender.address_unit +
                                " " +
                                orderData.sender.address_line_1 +
                                " " +
                                orderData.sender.address_line_2 +
                                " " +
                                orderData.sender.address_postcode +
                                " " +
                                orderData.sender.address_area +
                                " " +
                                orderData.sender.address_state +
                                " " +
                                orderData.sender.address_country,
                            }}
                          </span>
                          <div class="mt-3">
                            <span class="h1 font-medium-1">
                              {{ orderData.sender.phone }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender First Name"
                          rules="required"
                        >
                          <b-form-group label="First Name">
                            <input
                              v-model="orderData.sender.first_name"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender First Name"
                              aria-label="Sender First Name"
                            />
                          </b-form-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Last Name"
                          rules="required"
                        >
                          <b-form-group label="Last Name">
                            <input
                              v-model="orderData.sender.last_name"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Last Name"
                              aria-label="Sender Last Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Contact Number"
                          rules="required"
                        >
                          <b-form-group label="Contact Number">
                            <input
                              v-model="orderData.sender.phone"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Contact Number"
                              aria-label="Sender Contact Number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Address Unit"
                          rules="required"
                        >
                          <b-form-group label="Address Unit">
                            <input
                              v-model="orderData.sender.address_unit"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Address Unit"
                              aria-label="Sender Address Unit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Address Line 1"
                          rules="required"
                        >
                          <b-form-group label="Address Line 1">
                            <input
                              v-model="orderData.sender.address_line_1"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Address Line 1"
                              aria-label="Sender Address Line 1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Address Line 2"
                          rules="required"
                        >
                          <b-form-group label="Address Line 2">
                            <input
                              v-model="orderData.sender.address_line_2"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Address Line 2"
                              aria-label="Sender Address Line 2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Address Postcode"
                          rules="required"
                        >
                          <b-form-group label="Postcode">
                            <input
                              v-model="orderData.sender.address_postcode"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Address Postcode"
                              aria-label="Sender Address Postcode"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Address Area"
                          rules="required"
                        >
                          <b-form-group label="Area">
                            <input
                              v-model="orderData.sender.address_area"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Address Area"
                              aria-label="Sender Address Area"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Address State"
                          rules="required"
                        >
                          <b-form-group label="State">
                            <input
                              v-model="orderData.sender.address_state"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Address State"
                              aria-label="Sender Address State"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Sender Address Country"
                          rules="required"
                        >
                          <b-form-group label="Country">
                            <input
                              v-model="orderData.sender.address_country"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Sender Address Country"
                              aria-label="Sender Address Country"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>

                  <!-- Destination Address -->
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <span class="h1 font-medium-1 text-primary">
                      Destination Address
                    </span>
                    <div
                      class="w-100 border-primary rounded border-1 my-1 p-2 minimum-container-height"
                    >
                      <div v-if="!isAddressEditable">
                        <b
                          v-if="orderData.recipient.last_name"
                          class="h1 font-medium-3"
                        >
                          {{
                            orderData.recipient.first_name +
                            ' ' +
                            orderData.recipient.last_name
                          }}
                        </b>
                        <b v-else class="h1 font-medium-3">
                          {{ orderData.recipient.first_name }}
                        </b>
                        <br />
                        <div class="mt-1">
                          <span class="h1 font-medium-1">
                            {{
                              orderData.recipient.address_unit +
                                " " +
                                orderData.recipient.address_line_1 +
                                " " +
                                orderData.recipient.address_line_2 +
                                " " +
                                orderData.recipient.address_postcode +
                                " " +
                                orderData.recipient.address_area +
                                " " +
                                orderData.recipient.address_state +
                                " " +
                                orderData.recipient.address_country,
                            }}
                          </span>
                          <div class="mt-3">
                            <span class="h1 font-medium-1">
                              {{ orderData.recipient.phone }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient First Name"
                          rules="required"
                        >
                          <b-form-group label="First Name">
                            <input
                              v-model="orderData.recipient.first_name"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient First Name"
                              aria-label="Recipient First Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Last Name"
                          rules="required"
                        >
                          <b-form-group label="Last Name">
                            <input
                              v-model="orderData.recipient.last_name"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Last Name"
                              aria-label="Recipient Last Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Contact Number"
                          rules="required"
                        >
                          <b-form-group label="Contact Number">
                            <input
                              v-model="orderData.recipient.phone"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Contact Number"
                              aria-label="Recipient Contact Number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Address Unit"
                          rules="required"
                        >
                          <b-form-group label="Address Unit">
                            <input
                              v-model="orderData.recipient.address_unit"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Address Unit"
                              aria-label="Recipient Address Unit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Address Line 1"
                          rules="required"
                        >
                          <b-form-group label="Address Line 1">
                            <input
                              v-model="orderData.recipient.address_line_1"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Address Line 1"
                              aria-label="Recipient Address Line 1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Address Line 2"
                          rules="required"
                        >
                          <b-form-group label="Address Line 2">
                            <input
                              v-model="orderData.recipient.address_line_2"
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Address Line 2"
                              aria-label="Recipient Address Line 2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Address Postcode"
                          rules="required"
                        >
                          <b-form-group label="Postcode">
                            <input
                              v-model="orderData.recipient.address_postcode"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Address Postcode"
                              aria-label="Recipient Address Postcode"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Address Area"
                          rules="required"
                        >
                          <b-form-group label="Area">
                            <input
                              v-model="orderData.recipient.address_area"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Address Area"
                              aria-label="Recipient Address Area"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Address State"
                          rules="required"
                        >
                          <b-form-group label="State">
                            <input
                              v-model="orderData.recipient.address_state"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Address State"
                              aria-label="Recipient Address State"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                        <validation-provider
                          #default="{ errors }"
                          name="Recipient Address Country"
                          rules="required"
                        >
                          <b-form-group label="Country">
                            <input
                              v-model="orderData.recipient.address_country"
                              disabled
                              type="text"
                              class="form-control font-small-3"
                              placeholder="Recipient Address Country"
                              aria-label="Recipient Address Country"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </validation-observer>
              </div>
            </app-timeline-item>

            <!-- Product Details -->
            <app-timeline-item icon="BoxIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0">Product Details</span>
                  </div>
                </div>

                <b-card no-body>
                  <div class="ml-sm-5 col-xs-12 p-0 my-1 p-sm-3 rounded">
                    <vue-good-table
                      :columns="columns"
                      :rows="rows"
                      style-class="vgt-table border font-small-3"
                      :sort-options="{
                        enabled: false,
                      }"
                    />
                  </div>
                </b-card>
              </div>
            </app-timeline-item>

            <!-- Download Label -->
            <app-timeline-item icon="TagIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0"
                      >Delivery Order Label</span
                    >
                  </div>
                </div>

                <div
                  class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
                >
                  <b-button
                    class="w-100 font-small-3 my-2"
                    variant="gradient-primary"
                    pill
                    @click="generateLabel"
                  >
                    DOWNLOAD LABEL
                  </b-button>
                </div>
              </div>
            </app-timeline-item>

            <!-- Download Invoice  -->
            <app-timeline-item icon="CastIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0">Invoice</span>
                  </div>
                </div>

                <div
                  class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-4 p-0"
                >
                  <b-button
                    class="w-100 font-small-3 my-2"
                    variant="gradient-primary"
                    pill
                    @click="downloadInvoice"
                  >
                    DOWNLOAD INVOICE
                  </b-button>
                </div>
              </div>
            </app-timeline-item>

            <!-- Delivery Details  -->
            <app-timeline-item icon="TruckIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0">Delivery Details</span>
                  </div>

                  <b-button
                    v-if="!isRemarksEditable"
                    variant="gradient-primary"
                    class="box-shadow-1"
                    size="sm"
                    pill
                    @click="toggleEdit('remarks')"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="15"
                      color="white"
                      class="mr-1"
                    />
                    Edit
                  </b-button>
                  <div v-else>
                    <b-button
                      variant="gradient-secondary"
                      class="box-shadow-1 mr-1"
                      size="sm"
                      pill
                      @click="toggleEdit('remarks')"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      :disabled="
                        !orderData.remarks ||
                        previousRemarks === orderData.remarks
                      "
                      variant="gradient-success"
                      class="box-shadow-1"
                      size="sm"
                      pill
                      @click="updateRemarks()"
                    >
                      Save
                    </b-button>
                  </div>
                </div>
                <div
                  class="ml-sm-5 col-xs-10 col-sm-10 col-md-10 col-lg-6 col-xl-6 p-0"
                >
                  <!-- Assigned Driver -->
                  <div class="d-flex justify-content-between my-1">
                    <span class="h1 font-medium-1 text-primary"
                      >Assigned Driver</span
                    >
                    <span class="font-medium-1 mr-1 text-right">
                      {{
                        orderData.driver !== null
                          ? orderData.driver.name
                          : 'No Driver Assigned'
                      }}
                    </span>
                  </div>

                  <!-- Remarks -->
                  <div class="d-flex justify-content-between my-1">
                    <span class="h1 font-medium-1 text-primary pt-1">
                      Remarks
                    </span>

                    <validation-provider
                      #default="{ errors }"
                      name="Remarks"
                      rules="required"
                      class="w-50"
                    >
                      <input
                        v-model="orderData.remarks"
                        :disabled="!isRemarksEditable"
                        type="text"
                        class="form-control font-small-2"
                        placeholder="Remarks"
                        aria-label="Remarks"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </app-timeline-item>

            <!-- Proof of Delivery -->
            <app-timeline-item icon="HomeIcon" variant="info">
              <div class="w-100 mb-5">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <span class="h1 font-medium-3 m-0">Proof of Delivery</span>
                  </div>

                  <b-button
                    v-if="!isImageEditable"
                    variant="gradient-primary"
                    class="box-shadow-1"
                    size="sm"
                    pill
                    @click="toggleEdit('image')"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="15"
                      color="white"
                      class="mr-1"
                    />
                    Edit
                  </b-button>
                  <div v-else>
                    <b-button
                      variant="gradient-secondary"
                      class="box-shadow-1 mr-1"
                      size="sm"
                      pill
                      @click="toggleEdit('image')"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      :disabled="
                        this.pickUpPayload === this.dropOffPayload
                          ? true
                          : false
                      "
                      variant="gradient-success"
                      class="box-shadow-1"
                      size="sm"
                      pill
                      @click="updateImage()"
                    >
                      Save
                    </b-button>
                  </div>
                </div>

                <div
                  class="row ml-sm-5 col-xs-12 col-sm-10 col-md-10 px-0 my-1"
                >
                  <!-- Pick-Up Photo -->
                  <div
                    class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1 p-2"
                  >
                    <span class="h1 font-medium-1 text-primary">
                      Pick-Up Photo
                    </span>
                    <div
                      class="mt-1 mx-0 p-1 rounded box-shadow-1 d-flex justify-content-center align-items-center fixed-width-container"
                    >
                      <ImageUpload
                        label="pickUp"
                        :is-image-editable="isImageEditable"
                        :img="this.orderData.pick_up_photo"
                        @renderImage="renderImage"
                      />
                    </div>
                  </div>

                  <!-- Drop-Off Photo -->
                  <div
                    class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1 p-2"
                  >
                    <span class="h1 font-medium-1 text-primary">
                      Drop-Off Photo
                    </span>
                    <div
                      class="mt-1 mx-0 p-1 rounded box-shadow-1 d-flex justify-content-center align-items-center fixed-width-container"
                    >
                      <ImageUpload
                        label="dropOff"
                        :is-image-editable="isImageEditable"
                        :img="this.orderData.drop_off_photo"
                        @renderImage="renderImage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </app-timeline-item>
          </app-timeline>
        </div>
      </b-card>

      <div class="d-flex justify-content-center align-items-center mt-5">
        <b-button
          variant="gradient-primary"
          class="box-shadow-1 px-3 mx-2"
          @click="back"
        >
          Back
        </b-button>
      </div>

      <b-modal ref="downloadInvoice" hide-footer hide-title centered size="lg">
        <Invoice :order="orderData" />
      </b-modal>

      <b-modal ref="generateLabel" hide-footer hide-title centered size="lg">
        <OrderLabel :order="orderData" />
      </b-modal>
    </div>
    <div v-else class="text-center mt-5">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        variant="primary"
      />
      <h3 class="mt-3">Processing your order. Please wait...</h3>
    </div>
  </b-container>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import flatPickr from 'vue-flatpickr-component';
import { VueGoodTable } from 'vue-good-table';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import ImageUpload from '../../components/ImageUpload.vue';
import OrderLabel from './MyTask/OrderLabel.vue';
import Invoice from './OrderForms/components/Invoice.vue';

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    flatPickr,
    ImageUpload,
    Invoice,
    OrderLabel,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
  },

  data() {
    return {
      isLoading: true,
      isDateEditable: false,
      isAddressEditable: false,
      isRemarksEditable: false,
      isImageEditable: false,
      payload: {},
      previousDate: null,
      previousRemarks: null,
      orderID: this.$route.params.id,
      orderData: null,
      pickUpPayload: null,
      dropOffPayload: null,
      columns: [
        {
          label: 'Product Categories',
          field: 'prodCategory',
        },
        {
          label: 'Packaging',
          field: 'packaging',
        },
        {
          label: 'QTY',
          field: 'qty',
        },
        {
          label: 'Size',
          field: 'size',
        },
        {
          label: 'Weight (kg)',
          field: 'weight',
        },
        {
          label: 'SKU',
          field: 'sku',
        },
        {
          label: 'Temperature',
          field: 'temp',
        },
        {
          label: 'Remarks',
          field: 'remarks',
        },
      ],
      rows: [],
      required,
    };
  },

  async mounted() {
    // fetch order data w this.orderID
    await this.$http.get(`get_order/${this.orderID}`).then((response) => {
      if (Object.keys(response.data.data).length > 1) {
        this.orderData = response.data.data;
        this.previousDate = this.orderData.delivery_date;
        this.previousRemarks = this.orderData.remarks;
        this.rows.push({
          prodCategory: this.orderData.product_category,
          packaging: this.orderData.packaging,
          qty: this.orderData.quantity,
          size: this.orderData.item_size,
          weight: this.orderData.item_weight,
          sku: this.orderData.sku,
          temp: this.orderData.temperature,
          remarks: this.orderData.remarks,
        });
      }
      this.isLoading = false;
    });
  },

  methods: {
    back() {
      this.$router.back();
    },

    generateLabel() {
      this.$refs.generateLabel.show();
    },

    downloadInvoice() {
      this.$refs.downloadInvoice.show();
    },

    toggleEdit(value) {
      switch (value) {
        case 'date':
          this.isDateEditable = !this.isDateEditable;
          break;
        case 'address':
          this.isAddressEditable = !this.isAddressEditable;
          break;
        case 'remarks':
          this.isRemarksEditable = !this.isRemarksEditable;
          break;
        case 'image':
          this.isImageEditable = !this.isImageEditable;
          break;
      }
    },

    updateDate() {
      this.isLoading = true;
      this.$refs.dateValidation.validate().then((success) => {
        if (success) {
          this.payload = {
            order_id: this.orderID,
            delivery_date: this.orderData.delivery_date,
            remarks: this.orderData.remarks,
            sender_location_id: this.orderData.sender.id,
            sender_first_name: this.orderData.sender.first_name,
            sender_last_name: this.orderData.sender.last_name,
            sender_phone_number: this.orderData.sender.phone,
            sender_unit_number: this.orderData.sender.address_unit,
            sender_address_line_1: this.orderData.sender.address_line_1,
            sender_address_line_2: this.orderData.sender.address_line_2,
            sender_postcode: this.orderData.sender.address_postcode,
            sender_area: this.orderData.sender.address_area,
            sender_state: this.orderData.sender.address_state,
            sender_country: this.orderData.sender.address_country,
            recipient_location_id: this.orderData.recipient.id,
            recipient_first_name: this.orderData.recipient.first_name,
            recipient_last_name: this.orderData.recipient.last_name,
            recipient_phone_number: this.orderData.recipient.phone,
            recipient_unit_number: this.orderData.recipient.address_unit,
            recipient_address_line_1: this.orderData.recipient.address_line_1,
            recipient_address_line_2: this.orderData.recipient.address_line_2,
            recipient_postcode: this.orderData.recipient.address_postcode,
            recipient_area: this.orderData.recipient.address_area,
            recipient_state: this.orderData.recipient.address_state,
            recipient_country: this.orderData.recipient.address_country,
          };
          this.$http
            .post('update_order_address', this.payload)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Success !',
                  text: response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              }
              this.isLoading = false;
            });
          this.previousDate = this.orderData.delivery_date;
          this.isDateEditable = false;
        }
      });
    },

    updateAddress() {
      this.isLoading = true;
      this.$refs.addressValidation.validate().then((success) => {
        if (success) {
          this.payload = {
            order_id: this.orderID,
            delivery_date: this.orderData.delivery_date,
            remarks: this.orderData.remarks,
            sender_location_id: this.orderData.sender.id,
            sender_first_name: this.orderData.sender.first_name,
            sender_last_name: this.orderData.sender.last_name,
            sender_phone_number: this.orderData.sender.phone,
            sender_unit_number: this.orderData.sender.address_unit,
            sender_address_line_1: this.orderData.sender.address_line_1,
            sender_address_line_2: this.orderData.sender.address_line_2,
            sender_postcode: this.orderData.sender.address_postcode,
            sender_area: this.orderData.sender.address_area,
            sender_state: this.orderData.sender.address_state,
            sender_country: this.orderData.sender.address_country,
            recipient_location_id: this.orderData.recipient.id,
            recipient_first_name: this.orderData.recipient.first_name,
            recipient_last_name: this.orderData.recipient.last_name,
            recipient_phone_number: this.orderData.recipient.phone,
            recipient_unit_number: this.orderData.recipient.address_unit,
            recipient_address_line_1: this.orderData.recipient.address_line_1,
            recipient_address_line_2: this.orderData.recipient.address_line_2,
            recipient_postcode: this.orderData.recipient.address_postcode,
            recipient_area: this.orderData.recipient.address_area,
            recipient_state: this.orderData.recipient.address_state,
            recipient_country: this.orderData.recipient.address_country,
          };
          this.$http
            .post('update_order_address', this.payload)
            .then((response) => {
              if (response.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Success !',
                  text: response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                });
              }
              this.isLoading = false;
            });
          this.isAddressEditable = false;
        }
      });
    },

    updateRemarks() {
      this.isLoading = true;
      this.payload = {
        order_id: this.orderID,
        delivery_date: this.orderData.delivery_date,
        remarks: this.orderData.remarks,
        sender_location_id: this.orderData.sender.id,
        sender_first_name: this.orderData.sender.first_name,
        sender_last_name: this.orderData.sender.last_name,
        sender_phone_number: this.orderData.sender.phone,
        sender_unit_number: this.orderData.sender.address_unit,
        sender_address_line_1: this.orderData.sender.address_line_1,
        sender_address_line_2: this.orderData.sender.address_line_2,
        sender_postcode: this.orderData.sender.address_postcode,
        sender_area: this.orderData.sender.address_area,
        sender_state: this.orderData.sender.address_state,
        sender_country: this.orderData.sender.address_country,
        recipient_location_id: this.orderData.recipient.id,
        recipient_first_name: this.orderData.recipient.first_name,
        recipient_last_name: this.orderData.recipient.last_name,
        recipient_phone_number: this.orderData.recipient.phone,
        recipient_unit_number: this.orderData.recipient.address_unit,
        recipient_address_line_1: this.orderData.recipient.address_line_1,
        recipient_address_line_2: this.orderData.recipient.address_line_2,
        recipient_postcode: this.orderData.recipient.address_postcode,
        recipient_area: this.orderData.recipient.address_area,
        recipient_state: this.orderData.recipient.address_state,
        recipient_country: this.orderData.recipient.address_country,
      };
      this.$http.post('update_order_address', this.payload).then((response) => {
        if (response.data.status) {
          this.$swal({
            icon: 'success',
            title: 'Success !',
            text: response.data.message,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
        this.isLoading = false;
      });
      this.previousRemarks = this.orderData.remarks;
      this.isRemarksEditable = false;
    },

    async updateImage() {
      this.isLoading = true;
      this.isImageEditable = false;
      let message = '';
      if (this.pickUpPayload !== null) {
        const pickUpForm = new FormData();
        pickUpForm.append('delivery_image', this.pickUpPayload.delivery_image);
        pickUpForm.append('order_id', this.pickUpPayload.order_id);
        pickUpForm.append('type', this.pickUpPayload.type);

        await this.$http
          .post('upload_delivery_photo', pickUpForm)
          .then((response) => {
            if (response.data.status) {
              message = response.data.message;
              this.pickUpPayload = null;
            }
            this.isLoading = false;
          });
      }
      if (this.dropOffPayload !== null) {
        const dropOffForm = new FormData();
        dropOffForm.append(
          'delivery_image',
          this.dropOffPayload.delivery_image
        );
        dropOffForm.append('order_id', this.dropOffPayload.order_id);
        dropOffForm.append('type', this.dropOffPayload.type);
        await this.$http
          .post('upload_delivery_photo', dropOffForm)
          .then((response) => {
            if (response.data.status) {
              message = response.data.message;
              this.dropOffPayload = null;
            }
          });
      }

      if (message !== '') {
        this.$swal({
          icon: 'success',
          title: 'Success !',
          text: message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
    },

    renderImage(image, label) {
      if (label === 'pickUp') {
        this.pickUpPayload = {
          delivery_image: image,
          order_id: this.orderID,
          type: 'Pick-up',
        };
      } else {
        this.dropOffPayload = {
          delivery_image: image,
          order_id: this.orderID,
          type: 'Drop-off',
        };
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.icon-bg {
  background-color: #0f4392;
  height: 40px;
  width: 40px;
  margin-top: -10px;
  padding-top: 8px;
  padding-left: 8px;
}

.icon {
  top: 20%;
  left: 20%;
}

.fixed-width-container {
  height: 330px;
}

.minimum-container-height {
  min-height: 210px;
}

.border {
  border-width: 3px !important;
}
</style>
