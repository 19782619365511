<template>
  <b-container ref="invoice" class="d-flex flex-column p-0 m-0">
    <!-- Invoice Header -->
    <div class="d-flex justify-content-start">
      <div
        class="d-flex justify-content-center align-items-center bg-primary p-2"
      >
        <b-img fluid :src="imgUrl" alt="Logo" class="logo" />
        <span
          class="h1 font-medium-3 text-uppercase text-center text-white mr-2 ml-4 mb-0"
        >
          INVOICE
        </span>
      </div>
    </div>
    <!-- Invoice Content -->
    <div class="m-1">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <span class="h1 font-small-3 text-secondary mb-0">
            Receipt From GoBuilders Netsoft Sdn Bhd
          </span>
        </div>
        <div class="d-flex">
          <span
            class="h1 font-medium-3 text-right text-uppercase text-secondary mr-1 mb-0"
          >
            INVOICE# <br />
            {{ order.id }}
          </span>
          <div class="bg-primary invoice-box" />
        </div>
      </div>

      <div class="d-flex justify-content-start my-1">
        <div class="">
          <h4 class="text-uppercase text-secondary">BILLING TO</h4>
          <p>
            {{ order.sender.first_name }}
            {{ order.sender.last_name }}
          </p>
          <p>
            {{ order.sender.complete_address }}
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-start my-1">
        <div class="mr-4">
          <h4 class="text-uppercase text-secondary mb-0">ORDER PAID</h4>
          <p class="font-small-3">
            {{ order.order_placed_date.substr(0, 10) }}
          </p>
        </div>
        <div>
          <h4 class="text-uppercase text-secondary mb-0">PAYMENT METHOD</h4>
          <p class="font-small-3">
            {{ order.payment_method }}
          </p>
        </div>
      </div>

      <div class="d-flex justify-content-center my-1">
        <div class="w-100">
          <h4 class="text-uppercase text-secondary mb-1">DESCRIPTION</h4>
          <b-card no-body class="p-2 mb-1">
            <div class="d-flex justify-content-between">
              <span class="text-uppercase font-medium-1">
                {{ order.order_type }} ORDER x {{ order.quantity }}
              </span>
              <div>
                {{ $helpers.formatTotal(order.unit_price) }}
              </div>
            </div>
          </b-card>
        </div>
      </div>

      <div class="d-flex justify-content-end my-2">
        <div>
          <div class="d-flex align-items-center justify-content-between pb-1">
            <h5 class="text-uppercase text-secondary mr-3 my-0">SUB TOTAL</h5>
            <p class="m-0">
              {{
                $helpers.formatTotal(subtotal(order.quantity, order.unit_price))
              }}
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-between pb-1">
            <h5 class="text-uppercase text-secondary tax my-0 pr-2">
              TAX TOTAL
            </h5>
            <p class="m-0">
              {{
                $helpers.formatTotal(
                  taxcharge(order.quantity, order.unit_price, order.tax)
                )
              }}
            </p>
          </div>
          <div class="d-flex align-items-center justify-content-between pb-1">
            <h5 class="text-uppercase text-secondary mr-3 my-0">GRAND TOTAL</h5>
            <p class="m-0">
              {{ $helpers.formatTotal(order.amount) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Invoice Footer -->
    <div
      class="d-flex align-items-end justify-content-center bg-primary mt-2 p-1"
    >
      <span class="text-white font-medium-1">
        Golog Intelligent Cold Chain Logistic Platform
      </span>
    </div>
  </b-container>
</template>

<script>
import {
  BCard,
  BCardText,
  BContainer,
  BImg,
  BLink,
  BCol,
  BRow,
} from 'bootstrap-vue';
import store from '@/store/index';
import html2pdf from 'html2pdf.js';

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BContainer,
    BImg,
    BLink,
    BRow,
  },
  props: { order: {} },

  data() {
    return {
      sideImg: require('@/assets/images/logo/logo-white.png'),
      invoiceList: [
        {
          label: 'Outlet PAYMENT ORDER',
          qty: 1,
          price: 24.0,
        },
        {
          label: 'SINGAPORE PAYMENT ORDER',
          qty: 1,
          price: 24.0,
        },
      ],
    };
  },

  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/logo/logo-white.png');
        return this.sideImg;
      }
      return this.sideImg;
    },
    subtotal() {
      return (quantity, unit_price) => unit_price * quantity;
    },
    taxcharge() {
      return (quantity, unit_price, tax) => unit_price * quantity * (tax / 100);
    },
  },
  mounted() {
    html2pdf(this.$refs.invoice, {
      margin: 1,
      filename: 'invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    });
  },
};
</script>

<style scoped>
.logo {
  height: 60px;
  width: auto;
}

.invoice-box {
  height: auto;
  width: 50px;
}
.tax {
  margin-right: 4.5vw;
}
</style>
